export const MAX_Z_INDEX = '100000'

export const CONTAINER_TYPES = {
	Page: true,
	MasterPage: true,
	AppPage: true,
	VerticalRepeater: true,
	QuickActionBar: true,
	PageGroup: true,
	SiteRegionContainer: true,
	Container: true,
	MediaBox: true,
	HoverBox: true,
	PopupContainer: true,
	Group: true,
	FormContainer: true,
	WSiteStructure: true,
	HeaderContainer: true,
	FooterContainer: true,
	PagesContainer: true,
	StripContainer: true,
	StripColumnsContainer: true,
	ClassicSection: true,
	Column: true,
	MediaPlayer: true,
	ScreenWidthContainer: true,
	Area: true,
	BoxSlideShow: true,
	StateBox: true,
	StateStrip: true,
	StateBoxState: true,
	StateBoxFormState: true,
	StateStripState: true,
	StripContainerSlideShow: true,
	BoxSlideShowSlide: true,
	StripContainerSlideShowSlide: true,
	Repeater: true,
	MediaContainer: true,
	MenuContainer: true,
	AppWidget: true,
	Popover: true,
	RefComponent: true,
	Section: true,
	HeaderSection: true,
	FooterSection: true,
	MembersAreaSection: true,
	MultiStateBox: true,
	MegaMenuContainerItem: true,
	StretchedContainer: true,
	SettingsPanelContainer: true,
	SingleTab: true,
	AccordionItem: true,
}
